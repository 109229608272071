import React from "react";
import logo from "./logo.svg";

export default function Hello() {
  return (
    <div>
      <h1>Hola!</h1>
      <img src={logo} style={{ height: "40vmin" }} alt="logo" />
    </div>
  );
}
