import React, { Component } from "react";
import {
  saveGameData,
  deleteEgresos,
  deletePresupuestos,
  updateRequieredToken,
} from "./FirebaseService";
import { Layout } from "antd";
import { Container } from "@material-ui/core";
import { initialStatus, HOST } from "./constants";

class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      id: props.match.params.id,
      linkID: props.match.params.linkID,
    };
  }
  componentDidMount = async () => {
    var response = null;
    var body = null;
    if (this.state.linkID) {
      response = await fetch(`${HOST}/belvo/token?linkId=${this.state.linkID}`);
    } else {
      response = await fetch(`${HOST}/belvo/token`);
    }
    body = await response.json();
    var access_token = body.access;
    this.setState({ token: access_token });

    const widget = document.createElement("script");
    widget.setAttribute("src", "https://cdn.belvo.io/belvo-widget-1-stable.js");
    widget.async = true;
    document.body.appendChild(widget);
    widget.onload = () => this.scriptLoaded();
  };

  scriptLoaded() {
    window.belvoSDK
      .createWidget(this.state.token, {
        locale: "es", // 'en' for English or 'pt' for Portuguese
        company_name: "Fingame", // the name of the company to be displayed in the first screen
        //institution: 'banamex_mx_retail', // to start the widget directly on a specific institution credentials page
        institution_types: ["retail"], // to select the type of institution to show in the widget
        access_mode: "recurrent", // to specify the type of link to be created from the widget
        country_codes: ["MX"],
        callback: (link, institution) =>
          this.successCallbackFunction(link, institution),
        onExit: (data) => this.onExitCallbackFunction(data),
        onEvent: (data) => this.onEventCallbackFunction(data),
      })
      .build();
  }

  onEventCallbackFunction = (data) => {
    // console.log("onEventCallbackFunction: ", data);
  };

  onExitCallbackFunction = (data) => {
    // console.log("onExitCallbackFunction: ", data);
  };

  successCallbackFunction = async (link_id, institution) => {
    if (this.state.linkID) {
      updateRequieredToken(this.state.id);
    } else {
      initialStatus.idUserBelvo = link_id;
      initialStatus.site = institution;
      initialStatus.isSync = true;
      deletePresupuestos(this.state.id);
      deleteEgresos(this.state.id);
      saveGameData(this.state.id, {
        status: { ...initialStatus },
      });
    }
  };

  render() {
    return (
      <>
        <Layout className="layout">
          <Container>
            <div id="belvo"></div>
          </Container>
        </Layout>
      </>
    );
  }
}

export default home;
