const environment = {
  firebase: {
    apiKey: "AIzaSyDbTXpk9OIarj0y0PuCEhx6ZthhVAR_TWE",
    authDomain: "fingame-10c4a.firebaseapp.com",
    databaseURL: "https://fingame-10c4a.firebaseio.com",
    projectId: "fingame-10c4a",
    storageBucket: "fingame-10c4a.appspot.com",
    messagingSenderId: "15819843422",
  },
  mixpanel: {
    token: "551132d1ee5e6356ce26450f0c4b57e3",
  },
};

export default environment;
