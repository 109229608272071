import Environment from "./environment";
import Firebase from "firebase";
require("firebase/firestore");

const firebaseApp = Firebase.initializeApp(Environment.firebase);
const db = firebaseApp.firestore();

/**
 * Saves user state
 * @param {String} userId
 * @param {Object} data
 */
export const saveGameData = async (userId, data) => {
  await db
    .collection("gameStatus")
    .where("createdBy", "==", userId)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        return db
          .collection("gameStatus")
          .doc(doc.id)
          .set(data, { merge: true });
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
};

export const updateRequieredToken = async (userId) => {
  await db
    .collection("gameStatus")
    .where("createdBy", "==", userId)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        return db
          .collection("gameStatus")
          .doc(doc.id)
          .update({ "status.tokenRequired": false });
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
};

export const deleteEgresos = async (userId) => {
  await db
    .collection("gameStatus")
    .where("createdBy", "==", userId)
    .get()
    .then(async function (querySnapshot) {
      querySnapshot.forEach(async function (doc) {
        for (let index = 0; index < 49; index++) {
          let idTransaction = index + 1;
          db.collection("gameStatus")
            .doc(doc.id)
            .collection("egresos")
            .doc(`transaction${idTransaction}`)
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        }
      });
      // console.log("docid: ", docId);
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
};

export const deletePresupuestos = async (userId) => {
  await db
    .collection("gameStatus")
    .where("createdBy", "==", userId)
    .get()
    .then(async function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // console.log(doc.id); // se asigna el id a la variable creada
        var categorias = [
          "comida",
          "entretenimiento",
          "hogar",
          "otros",
          "ropa",
          "salud",
          "transporte",
          "viajes",
        ];

        categorias.forEach(async (categoria) => {
          await db
            .collection("gameStatus")
            .doc(doc.id)
            .collection("presupuestos")
            .doc(categoria)
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        });
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
};
