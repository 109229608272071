import React, { Component } from "react";
import home from "./home";
import Hello from "./Hello";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      cookie: false,
    };
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/" exact={true} component={Hello} />
            <Route path="/connect/:id" exact={true} component={home} />
            <Route path="/connect/:id/:linkID" exact={true} component={home} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
