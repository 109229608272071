export const HOST = "https://widget-fingame-backend.herokuapp.com";
// export const HOST = "http://localhost:5000";
export const initialStatus = {
  isSync: false,
  tokenRequired: false,
  idUserBelvo: "",
  idAccountBelvo: "",
  cash: 1120,
  balanceAccount: 0,
  site: "",
  logoSite: "",
  coins: 995,
  hasOneTCoins: false,
  defaultsavedAmount: 50,
  expenses: {
    level: 1,
    step: 0,
    experience: 0,
    levelOverview: true,
    lastExpenseRanked: Date.now(),
    cooldown: 0,
    events: [],
    biweeklyIncome: 0,
    monthlyIncome: 0,
    percentage: 0,
    continuidadBudget: 0,
    expenseToRank: true,
    isATM: 0,
    recompensaATMaplicada: true,
  },
  savings: {
    enable: false, // Revisa si esta habilidata la screen
    level: 1,
    experience: 0,
    savedAmount: 0, // Cuanto tienes ahorrado
    difference: 0, // Cantidad de ingreso nuevo para alimentar a barriga
    isANewIncome: false,
    step: 0,
    levelOverview: true,
    lastSaving: Date.now(), //Contador de cuando alimentaste -> null
    lastDaySavedAmount: null,
    daySavedAmount: 0, //Contador de continuidad
    lastSavedAmountChecked: 0, // Guarda la cantidad que ahorraste una semana antes
    lastSavedPercentage: 0,
    isATM: 0, //checar si hubo retiro
    lastSavedAmount: 0, //Este es para contar lo que haz ahorrado esta semana
    cooldown: 0,
    events: [],
    continuidadIngreso: 0,
    streak: 0,
    weekSaved: 0,
    weekCounter: 0,
    lastWeekSaving: 0,
    lastWeekPercentage: 0,
    weekPercentage: 0,
    weekCounterPercentage: 0,
  },
  budgets: {
    biweeklyIncome: 0,
    budgetSet: false,
    readyToSet: true,
    endDay: "",
    startDay: "",
    streak: 0,
    firstSet: 0,
  },
  items: {
    item1: 0,
    item2: 0,
    item3: 0,
    item4: 0,
    item5: 0,
  },
  sentNotifications: {},
  receivedNotifications: [], //cached
  isBootstrapDone: false, //cached
};
